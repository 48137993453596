import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import { FormattedMessage } from '../../../util/reactIntl';
import { richText } from '../../../util/richText';
import { formatMoney, formatMoneyRoundWithoutDecimals } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';

import ListingImageGallery from '../ListingImageGallery/ListingImageGallery';
import ListingCard from './ListingCard/ListingCard';
import {
    Heading,
    OrderPanel,
    H4,
} from '../../../components';

import css from './RentalsSection.module.css';

const { Money } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const Swiper =
    typeof window !== 'undefined'
        ? require('swiper/react').Swiper
        : dynamic(() => import('swiper/react'), {
            ssr: false,
        });
const SwiperSlide =
    typeof window !== 'undefined'
        ? require('swiper/react').SwiperSlide
        : dynamic(() => import('swiper/react'), {
            ssr: false,
        });

const RentalsSection = props => {
    const {
        rentals,
        variantPrefix,
        currentListing,
        marketplaceCurrency,
        intl,

        isOwnListing,
        onSubmit,
        authorLink,
        title,
        titleDesktop,
        payoutDetailsWarning,
        author,
        onManageDisableScrolling,
        onContactUser,
        monthlyTimeSlots,
        onFetchTimeSlots,
        onFetchTransactionLineItems,
        lineItems,
        fetchLineItemsInProgress,
        fetchLineItemsError,
        validListingTypes,
        dayCountAvailableForBooking,
        marketplaceName,
        activeRental,
        setActiveRental,
    } = props;

    useEffect(() => {
        // Load swiper CSS only in the browser
        if (typeof window !== 'undefined') {
            require('swiper/css');
            require('swiper/css/bundle');
        }
    }, []);

    useEffect(() => {
        if (rentals && rentals.length > 0) {
            const newActiveRental = currentListing.attributes.publicData.listingType === 'daily-booking' ? currentListing : rentals[0];
            if (!activeRental || activeRental.id.uuid !== newActiveRental.id.uuid) {
                setActiveRental(newActiveRental);
            }
        }
    }, [rentals]);

    const images = (activeRental && activeRental.images) ? activeRental.images : [];
    const imageVariants = ['scaled-medium', 'scaled-large', 'scaled-xlarge'];
    const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

    const publicData = activeRental?.attributes?.publicData || {};
    const { displayType, displayLength, measureUnit, finalDisplayRate, spaceDescription } = publicData;


    const rentalRichTitle = (
        <span>
            {richText(activeRental?.attributes?.title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                longWordClass: css.longWord,
            })}
        </span>
    );

    const formattedFinalDisplayRate = finalDisplayRate && finalDisplayRate.amount
        ? formatMoneyRoundWithoutDecimals(intl, new Money(finalDisplayRate.amount, marketplaceCurrency)) + " day"
        : '';

    return (
        <div className={css.root}>
            <div className={css.rentalGallery} data-testid="carousel">
                <ListingImageGallery
                    images={images}
                    imageVariants={imageVariants}
                    thumbnailVariants={thumbnailVariants}
                />
                <div>
                    <Heading as="h2" rootClassName={css.sectionHeading} style={{ marginTop: 21, marginBottom: 0 }}>
                        {rentalRichTitle}
                    </Heading>
                    {displayType && displayType
                        .map(displayItem => intl.formatMessage({ id: `RentalsSection.displayType.${displayItem}` }))
                        .join(', ')
                    }
                    <br />
                    {(displayLength && measureUnit) &&
                        displayLength + measureUnit
                    }
                    <br />
                    <strong>{formattedFinalDisplayRate}</strong>
                    {spaceDescription && <p>{spaceDescription}</p>}
                </div>
            </div>
            <div>
                <Heading as="h2" rootClassName={css.sectionHeading}>
                    <FormattedMessage id="RentalsSection.title" />
                </Heading>
                {rentals &&
                    <Swiper
                        className={css.swiperForListings}
                        loop={true}
                        spaceBetween={5}
                        slidesPerView={3}
                    >
                        {rentals.map(l => (
                            <SwiperSlide key={l.id.uuid}>
                                <ListingCard
                                    listing={l}
                                    setActiveListing={setActiveRental}
                                    activeListing={activeRental}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                }

                <OrderPanel
                    className={css.productOrderPanel}
                    listing={activeRental}
                    isOwnListing={isOwnListing}
                    onSubmit={onSubmit}
                    authorLink={authorLink}
                    title={title}
                    titleDesktop={titleDesktop}
                    payoutDetailsWarning={payoutDetailsWarning}
                    author={author}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onContactUser={onContactUser}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    validListingTypes={validListingTypes}
                    marketplaceCurrency={marketplaceCurrency}
                    dayCountAvailableForBooking={dayCountAvailableForBooking}
                    marketplaceName={marketplaceName}
                />
            </div>
        </div>
    )
};

export default RentalsSection;
